















import Vue from 'vue'
import ModelMenu from './model-page/menu.vue'
import DbView from './model-page/db.vue'
import ContactMap from './contact-map.vue'
import ReactivityNetwork from './reactivity-network.vue'

export default Vue.extend({
  name: 'viz',
  data() {
    return {
      split: {
        vertical: 0.2,
        leftHorizontal: 0.5,
        rightHorizontal: 0.5,
      },
    }
  },
  components: {
    'model-menu': ModelMenu,
    'db-view': DbView,
    'contact-map': ContactMap,
    'reactivity-network': ReactivityNetwork,
  },
})
